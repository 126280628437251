<template>
  <a-modal
    v-model:visible="modelValue"
    size="small"
    title="No. of Cases (YTD)"
    @cancel="() => $emit('update:modelValue', false)"
    :footer="false"
    :width="850"
  >
    <a-spin :spinning="loading">
      <div id="columnChart" />
    </a-spin>
  </a-modal>
</template>

<script>
import { Column } from "@antv/g2plot";
import { commonService } from "../../../services";

export default {
  props: ["modelValue", "hospitalId"],
  emits: ["update:modelValue"],

  data() {
    return {
      loading: true,
      columnChart: null,
      data: [],
      chartConfig: {
        xField: "month",
        yField: "count",
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        meta: {
          month: {
            alias: "Month",
          },
          amount: {
            alias: "Count",
          },
        },
        tooltip: {
          formatter: (v) => ({
            name: "Count",
            value: v.count,
          }),
        },
        label: {
          position: "top",
        },
        legend: false,
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        color: "#023958",
      },
    };
  },

  watch: {
    modelValue(value) {
      if (value) {
        this.fetch();
      }
    },
  },

  mounted() {
    // this.prepareChart();
  },

  methods: {
    fetch() {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalNoOfCaseReportsPerMonthUrl, {
          hospital_id: this.hospitalId,
        })
        .then((res) => {
          this.data = res.data;

          this.prepareChart();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    prepareChart() {
      let counts = this.data.map((e) => e.count);
      let maxCount = Math.max(...counts);
      let max = Math.round(maxCount + maxCount * 0.1);
      let data = {
        data: this.data,
        ...this.chartConfig,
        yAxis: {
          max,
        },
      };

      if (this.columnChart) {
        this.columnChart.update(data);
      } else {
        this.columnChart = new Column("columnChart", data);
        this.columnChart.render();
      }
    },
  },
};
</script>
